import enova from "../../images/ENOVA-GUIDE.png";
import g5 from "../../images/g5.png";
import g7 from "../../images/g7.jpg";
import g8 from "../../images/g8.png";
import joare from "../../images/portfolio/joare/joare.png";
import joare2 from "../../images/portfolio/joare/joare2.png";
import joare3 from "../../images/portfolio/joare/joare3.png";
import joare4 from "../../images/portfolio/joare/joare4.png";
import lineUp from "../../images/portfolio/line-Up/line-Up.png";
import lineUp2 from "../../images/portfolio/line-Up/line-Up2.png";
import lineUp3 from "../../images/portfolio/line-Up/line-Up3.png";
import lineUp4 from "../../images/portfolio/line-Up/line-Up4.png";
import twsBrasil from "../../images/portfolio/twsBrasil/twsBrasil.png";
import twsBrasil2 from "../../images/portfolio/twsBrasil/twsBrasil2.png";
import twsBrasil3 from "../../images/portfolio/twsBrasil/twsBrasil3.png";
import twsBrasil4 from "../../images/portfolio/twsBrasil/twsBrasil4.png";
import financeiro from "../../images/portfolio/financeiro/financeiro.png";
import financeiro2 from "../../images/portfolio/financeiro/financeiro2.png";
import financeiro3 from "../../images/portfolio/financeiro/financeiro3.png";
import financeiro4 from "../../images/portfolio/financeiro/financeiro4.png";
import financeiro5 from "../../images/portfolio/financeiro/financeiro5.png";
import notasdeumprovavelleitor from "../../images/portfolio/notasdeumprovavelleitor-front/notasdeumprovavelleitor.png";
import notasdeumprovavelleitor2 from "../../images/portfolio/notasdeumprovavelleitor-front/notasdeumprovavelleitor2.png";
import seletivoTutor from "../../images/portfolio/seletivoTutor/seletivoTutor.png";
import seletivoTutor2 from "../../images/portfolio/seletivoTutor/seletivoTutor2.png";
import seletivoTutor3 from "../../images/portfolio/seletivoTutor/seletivoTutor3.png";
import seletivoTutor4 from "../../images/portfolio/seletivoTutor/seletivoTutor4.png";
import seletivoTutor5 from "../../images/portfolio/seletivoTutor/seletivoTutor5.png";

const projectsData = [
  {
    nome: "joare",
    text: "site joare",
    img: joare,
    img_inside: [joare, joare2, joare3, joare4],
    type: "Web Site",
  },
  {
    nome: "enova",
    text: "site enova",
    img: enova,
    img_inside: [enova],
    type: "Mobile App",
  },
  {
    nome: "twsbrasil",
    id: 2,
    text: "site twsbrasil",
    img: twsBrasil,
    img_inside: [twsBrasil, twsBrasil2, twsBrasil3, twsBrasil4],
    type: "Web Site",
  },
  {
    nome: "lineUp",
    text: "site twsbrasil",
    img: lineUp,
    img_inside: [lineUp, lineUp2, lineUp3, lineUp4],
    type: "Web App",
  },

  {
    nome: "financeiro",
    text: "sistema",
    img: financeiro,
    img_inside: [financeiro2, financeiro3, financeiro4, financeiro5],
    type: "Web App",
  },
  {
    nome: "notasdeumprovavelleitor",
    text: "site",
    img: notasdeumprovavelleitor,
    img_inside: [notasdeumprovavelleitor, notasdeumprovavelleitor2],
    type: "Web Site",
  },
  {
    nome: "seletivoTutor",
    text: "sistema",
    img: seletivoTutor,
    img_inside: [
      seletivoTutor2,
      seletivoTutor3,
      seletivoTutor4,
      seletivoTutor5,
    ],
    type: "Web App",
  },

];

export default projectsData;
