import React from "react";
import "./Toogler.css";
import { useTranslation } from "react-i18next";
import "../../i18n";
import i16n from "../../i18n";

const Toogler = (props) => {
  const { i18n } = useTranslation();
  const handleChange = (e) => {
    if (e) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("pt");
    }
  };

  return (
    <div className="toogleContainer">
      <span>PT</span>
      <label class="toggle" for="myToggle">
        <input
          onChange={(e) => handleChange(e.target.checked)}
          class="toggle__input"
          name=""
          type="checkbox"
          id="myToggle"
        />
        <div class="toggle__fill"></div>
      </label>
      <span>EN</span>
    </div>
  );
};

export default Toogler;
