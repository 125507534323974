import React, { Fragment, useRef, useEffect } from "react";
import "./Header.css";
import logo from "../../images/Rob_Castro.svg";
import logoName from "../../images/logo_name.png";
import logoTsuru from "../../images/logoTsuru.png";
import rob from "../../images/rob-main.png";
import hideShow from "./hideShowHeader.js";
import lightning from "../../images/lightning.png";
import vscode from "../../images/vscode.png";
import Toogler from "../Toogler/Toogler";
import { useTranslation } from "react-i18next";
import "../../i18n";
import i16n from "../../i18n";

const Header = (props) => {
  const header = useRef(null);
  const x = useRef(null);

  useEffect(() => {
    hideShow(header);
  }, []);

  const { t, i18n } = useTranslation();

  return (
    <Fragment>
      <main className="post" ref={x}>
        <div className="Header__post-image parallax ">
          <div className="Header__img-color-overlay ">
            <header className="Header__header" id="site-header" ref={header}>
              <div>
                <a href="" className="Header__logo_link ">
                  <img className="Header__logo" src={logoTsuru} alt="" />
                  {/* {"<RC/>"} */}
                </a>

                <ul className="social-networks-items">
                  <li>
                    <Toogler />
                  </li>
                  <li className="">
                    <a href="https://github.com/RobinsonCastro" target="_blank">
                      <svg
                        stroke="currentColor"
                        fill="white"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1.6em"
                        width="1.6em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M511.6 76.3C264.3 76.2 64 276.4 64 523.5 64 718.9 189.3 885 363.8 946c23.5 5.9 19.9-10.8 19.9-22.2v-77.5c-135.7 15.9-141.2-73.9-150.3-88.9C215 726 171.5 718 184.5 703c30.9-15.9 62.4 4 98.9 57.9 26.4 39.1 77.9 32.5 104 26 5.7-23.5 17.9-44.5 34.7-60.8-140.6-25.2-199.2-111-199.2-213 0-49.5 16.3-95 48.3-131.7-20.4-60.5 1.9-112.3 4.9-120 58.1-5.2 118.5 41.6 123.2 45.3 33-8.9 70.7-13.6 112.9-13.6 42.4 0 80.2 4.9 113.5 13.9 11.3-8.6 67.3-48.8 121.3-43.9 2.9 7.7 24.7 58.3 5.5 118 32.4 36.8 48.9 82.7 48.9 132.3 0 102.2-59 188.1-200 212.9a127.5 127.5 0 0 1 38.1 91v112.5c.8 9 0 17.9 15 17.9 177.1-59.7 304.6-227 304.6-424.1 0-247.2-200.4-447.3-447.5-447.3z"></path>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/robcastro.dev/"
                      target="_blank"
                    >
                      <svg
                        stroke="currentColor"
                        fill="white"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1.6em"
                        width="1.6em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path
                            fill-rule="nonzero"
                            d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z"
                          ></path>
                        </g>
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/robinsoncastro-dev/"
                      target="_blank"
                    >
                      <svg
                        stroke="green"
                        fill="white"
                        stroke-width="0"
                        viewBox="0 0 1024 1024"
                        height="1.6em"
                        width="1.6em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M847.7 112H176.3c-35.5 0-64.3 28.8-64.3 64.3v671.4c0 35.5 28.8 64.3 64.3 64.3h671.4c35.5 0 64.3-28.8 64.3-64.3V176.3c0-35.5-28.8-64.3-64.3-64.3zm0 736c-447.8-.1-671.7-.2-671.7-.3.1-447.8.2-671.7.3-671.7 447.8.1 671.7.2 671.7.3-.1 447.8-.2 671.7-.3 671.7zM230.6 411.9h118.7v381.8H230.6zm59.4-52.2c37.9 0 68.8-30.8 68.8-68.8a68.8 68.8 0 1 0-137.6 0c-.1 38 30.7 68.8 68.8 68.8zm252.3 245.1c0-49.8 9.5-98 71.2-98 60.8 0 61.7 56.9 61.7 101.2v185.7h118.6V584.3c0-102.8-22.2-181.9-142.3-181.9-57.7 0-96.4 31.7-112.3 61.7h-1.6v-52.2H423.7v381.8h118.6V604.8z"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
              </div>
            </header>

            <div className="Header__headline ">
              <div className="Header__headline_box ">
                <img
                  className="profile_photo "
                  src={rob}
                  alt="robinson castro"
                />
                <h1>Robinson Castro</h1>

                <div className="Header__skills">
                  <img src={lightning} alt="" />
                  <h2>{t("skill1")}</h2>
                </div>
                <div className="Header__skills">
                  <img src={lightning} alt="" />
                  <h2>{t("skill2")}</h2>
                </div>
                <div className="Header__skills">
                  <img src={lightning} alt="" />
                  <h2>{t("skill3")}</h2>
                </div>
                <div className="Header__skills">
                  <img src={lightning} alt="" />
                  <h2>{t("skill4")}</h2>
                </div>
              </div>

              <div className="img-box ">
                <img
                  className="img-vscode"
                  src={vscode}
                  alt="robinson castro"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default Header;
