import React from "react";
import "./Footer.css";

const Footer = (props) => {
  return (
    <div className="Footer__footer">
      <p class="text-xl text-center">
        Made by <span class="text-3xl text-pink-400 love"></span> Robinson
        Castro
      </p>
    </div>
  );
};

export default Footer;
