export default function (header) {
  var doc = document.documentElement;
  var w = window;

  var prevScroll = w.scrollY || doc.scrollTop;
  var curScroll;
  var direction = 0;
  var prevDirection = 0;

  var checkScroll = function () {
    /*
     ** Find the direction of scroll
     ** 0 - initial, 1 - up, 2 - down
     */
    //  console.log("doc.scrollTop ", doc.scrollTop);
    //  console.log("w.scrollY  ", w.scrollY);

    //  console.log("w.scrollY che", w.scrollY);

    if (w.scrollY == 0) {
      header.current.classList.remove("background-transparency");
    }

    curScroll = w.scrollY || doc.scrollTop;
    if (curScroll > prevScroll) {
      //scrolled up
      direction = 2;
    } else if (curScroll < prevScroll) {
      //scrolled down
      direction = 1;
    }

    if (direction !== prevDirection) {
      toggleHeader(direction, curScroll);
    }

    prevScroll = curScroll;

    //  console.log("curScroll  ", curScroll);
  };

  var toggleHeader = function (direction, curScroll) {
    //  console.log("direction", direction);
    //  console.log("curScroll", curScroll);

    if (direction === 2 && curScroll > 52) {
      //replace 52 with the height of your header in px

      header.current.classList.add("hide");
      prevDirection = direction;
    } else if (direction === 1) {
      header.current.classList.remove("hide");
      header.current.classList.add("background-transparency");
      prevDirection = direction;
    }

    //  console.log("w.scrollY dd", w.scrollY);
  };

  window.addEventListener("scroll", checkScroll);
}
