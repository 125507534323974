import React, { useState, useEffect } from "react";
import "./Portifolio.scss";
import projectsData from "./projectsData";
import { AiFillEye, AiFillGithub } from 'react-icons/ai';
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion/dist/framer-motion";
import { MotionWrap } from '../../wrapper';


console.log("projectsData", projectsData);

const Portifolio = (props) => {
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 }); const { t } = useTranslation();
  useEffect(() => {
    const query = '*[_type == "works"]';

    setWorks(projectsData);
    setFilterWork(projectsData);

  }, []);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'all') {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.type.includes(item)));
      }
    }, 500);
  };

  return (
    <div className="app__works">
      <h1 className="sectionTitle">{t("portfolio")}</h1>
      <div className="app__work-filter">
        {['Web App', 'Mobile App', 'Web Site', 'all'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {t(item)}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <div className="app__work-item app__flex" key={index}>
            <div
              className="app__work-img app__flex"
            >
              <img src={work.img} alt={work.nome} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{ duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5 }}
                className="app__work-hover app__flex"
              >
                <a href={work.projectLink} target="_blank" rel="noreferrer">

                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.90] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
           
              </motion.div>
            </div>

          
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default MotionWrap(Portifolio, 'app__about')
