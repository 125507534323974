import React, { Component, Fragment, useEffect } from "react";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import About from "./components/About/About";
import Portifolio from "./components/Portifolio/Portifolio";
import Contact from "./components/Contact/Contact";
import "./App.css";


import ReactGA from "react-ga";

const TRACKING_ID = "UA-116419523-1"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Fragment>
      <Header />
      <About />
      <Portifolio />
      <Contact />
      <Footer />
    </Fragment>
  );
}

export default App;
