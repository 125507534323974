import React from "react";
import "./About.css";
import css from "../../images/tecnologies/css.png";
import html from "../../images/tecnologies/html.png";
import javascript from "../../images/tecnologies/javascript.png";
import nodejs from "../../images/tecnologies/nodejs.png";
import react from "../../images/tecnologies/react.png";
import typescript from "../../images/tecnologies/typescript.png";
import laravel from "../../images/tecnologies/laravel.png"
import { useTranslation } from "react-i18next";
import { MotionWrap } from '../../wrapper';

const About = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <section className="About__container  ">
      <div className="About__description"  dangerouslySetInnerHTML={{
            __html: t("about_me"),
          }}>
      
      </div>
      <div className="About__tecnologies">
        <ul>
          <li>
            <img src={html} alt="html" />
          </li>
          <li>
            <img src={css} alt="css" />
          </li>
          <li>
            <img src={javascript} alt="javascript" />
          </li>
          <li>
            <img src={nodejs} alt="nodejs" />
          </li>
          <li>
            <img src={react} alt="react" />
          </li>
          <li>
            <img src={typescript} alt="typescript" />
          </li>
          <li>
            <img src={laravel} alt="typescript" />
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MotionWrap(About, 'app__about')
